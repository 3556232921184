import React, { useState, useEffect } from 'react';
import { AlertCircle, CheckCircle, XCircle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from './components/ui/alert';
import { Button } from './components/ui/button';
import { Card, CardHeader, CardTitle, CardContent } from './components/ui/card';
import { RadioGroup, RadioGroupItem } from './components/ui/radio-group';
import { Label } from './components/ui/label';

const CourtCaseApp = () => {
  const [currentCase, setCurrentCase] = useState(null);
  const [questions, setQuestions] = useState({ plaintiff: [], defendant: [] });
  const [appJudgment, setAppJudgment] = useState(null);
  const [userJudgment, setUserJudgment] = useState(null);
  const [explanation, setExplanation] = useState("");
  const [score, setScore] = useState({ correct: 0, total: 0 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    generateRandomCase();
  }, []);

  const generateRandomCase = async () => {
    setLoading(true);
    try {
      const response = await fetch('http://localhost:11434/api/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: "llama3",
          stream: false,
          prompt: `Generate a court case scenario.
          Make sure the case is interesting and has enough complexity for judgment.
          Include at least 5 answers for both plaintiff and defendant.
          Please respond with only a single JSON object and no additional text. 
          Answer must be in the following JSON format.
          {
            "title": "Case title",
            "description": "Brief description of the case",
            "plaintiff": {
              "name": "Plaintiff name",
              "statement": "Plaintiff's statement",
              "answers": ["Answer 1", "Answer 2", "Answer 3", "Answer 4", "Answer 5"]
            },
            "defendant": {
              "name": "Defendant name",
              "statement": "Defendant's statement",
              "answers": ["Answer 1", "Answer 2", "Answer 3", "Answer 4", "Answer 5"]
            }
          }`
        }),
      });
      
      const data = await response.json();
      const newCase = JSON.parse(data.response);
      setCurrentCase(newCase);
      setQuestions({ plaintiff: [], defendant: [] });
      setAppJudgment(null);
      setUserJudgment(null);
      setExplanation("");
    } catch (error) {
      console.error("Error generating case:", error);
      alert("Failed to generate a new case. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const askQuestion = (party) => {
    if (questions[party].length < 5) {
      const newQuestions = { ...questions };
      newQuestions[party].push(currentCase[party].answers[questions[party].length]);
      setQuestions(newQuestions);
    }
  };

  const determineGuilty = async () => {
    setLoading(true);
    try {
      const response = await fetch('http://localhost:11434/api/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: "llama3",
          stream: false,
          prompt: `Based on the following court case and evidence, determine who is guilty (plaintiff, defendant, or inconclusive) and provide an explanation for the judgment.
          
          Case: ${JSON.stringify(currentCase)}
          
          Questions asked to plaintiff: ${JSON.stringify(questions.plaintiff)}
          Questions asked to defendant: ${JSON.stringify(questions.defendant)}
          
          Please respond with only a single JSON object and no additional text. Answer must be in the following JSON format:
          {
            "judgment": "plaintiff/defendant/inconclusive",
            "explanation": "Detailed explanation of the judgment"
          }`
        }),
      });
      
      const data = await response.json();
      const result = JSON.parse(data.response);
      
      setAppJudgment(result.judgment);
      setExplanation(result.explanation);

      // Update score
      if (userJudgment) {
        setScore(prevScore => ({
          correct: prevScore.correct + (userJudgment === result.judgment ? 1 : 0),
          total: prevScore.total + 1
        }));
      }
    } catch (error) {
      console.error("Error determining judgment:", error);
      alert("Failed to determine judgment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleUserJudgment = (value) => {
    setUserJudgment(value);
  };

  if (!currentCase) return <div>Loading...</div>;

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Court Case: {currentCase.title}</h1>
      <p className="mb-4">{currentCase.description}</p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <Card>
          <CardHeader>
            <CardTitle>Plaintiff: {currentCase.plaintiff.name}</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="mb-2">{currentCase.plaintiff.statement}</p>
            <Button onClick={() => askQuestion('plaintiff')} disabled={questions.plaintiff.length >= 5 || loading}>
              Ask Question ({questions.plaintiff.length}/5)
            </Button>
            <ul className="mt-2">
              {questions.plaintiff.map((q, index) => (
                <li key={index} className="mb-1">Q{index + 1}: {q}</li>
              ))}
            </ul>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Defendant: {currentCase.defendant.name}</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="mb-2">{currentCase.defendant.statement}</p>
            <Button onClick={() => askQuestion('defendant')} disabled={questions.defendant.length >= 5 || loading}>
              Ask Question ({questions.defendant.length}/5)
            </Button>
            <ul className="mt-2">
              {questions.defendant.map((q, index) => (
                <li key={index} className="mb-1">Q{index + 1}: {q}</li>
              ))}
            </ul>
          </CardContent>
        </Card>
      </div>

      <Card className="mb-4">
        <CardHeader>
          <CardTitle>Your Judgment</CardTitle>
        </CardHeader>
        <CardContent>
          <RadioGroup onValueChange={handleUserJudgment} disabled={appJudgment !== null || loading}>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="plaintiff" id="plaintiff" />
              <Label htmlFor="plaintiff">Plaintiff is guilty</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="defendant" id="defendant" />
              <Label htmlFor="defendant">Defendant is guilty</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="inconclusive" id="inconclusive" />
              <Label htmlFor="inconclusive">Case is inconclusive</Label>
            </div>
          </RadioGroup>
        </CardContent>
      </Card>

      <div className="flex justify-center mb-4">
        <Button onClick={determineGuilty} disabled={appJudgment !== null || !userJudgment || loading}>
          {loading ? "Processing..." : "Make Judgment"}
        </Button>
      </div>

      {appJudgment && (
        <Alert className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>App's Judgment</AlertTitle>
          <AlertDescription>
            {appJudgment === "inconclusive" 
              ? "The case is inconclusive due to lack of clear evidence." 
              : `The ${appJudgment} is found guilty.`}
          </AlertDescription>
        </Alert>
      )}

      {userJudgment && appJudgment && (
        <Alert className={`mb-4 ${userJudgment === appJudgment ? "bg-green-100" : "bg-red-100"}`}>
          {userJudgment === appJudgment ? (
            <CheckCircle className="h-4 w-4 text-green-600" />
          ) : (
            <XCircle className="h-4 w-4 text-red-600" />
          )}
          <AlertTitle>Your Judgment</AlertTitle>
          <AlertDescription>
            {userJudgment === appJudgment 
              ? "Your judgment matches the app's judgment. Good job!" 
              : "Your judgment differs from the app's judgment."}
          </AlertDescription>
        </Alert>
      )}

      {explanation && (
        <Card className="mb-4">
          <CardHeader>
            <CardTitle>Explanation</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="whitespace-pre-line">{explanation}</p>
          </CardContent>
        </Card>
      )}

      <Card className="mb-4">
        <CardHeader>
          <CardTitle>Your Score</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Correct: {score.correct} / Total: {score.total}</p>
        </CardContent>
      </Card>

      <div className="mt-4">
        <Button onClick={generateRandomCase} disabled={loading}>
          {loading ? "Generating..." : "New Case"}
        </Button>
      </div>
    </div>
  );
};

export default CourtCaseApp;